export const reqLogic = {
  OR: 'ohbot 管理員',
  AND: 'ohbot 成功專員',
  ohbotGuset: '訪客',
  orgAdmin: '集團管理員',
  shopManager: '店家店長',
  shopProvider: '店家服務人員',
}

export const paymentConfig = {
  jkos: '街口支付',
  newebpay: '藍新支付',
  atm: 'ATM轉帳',
  COD: '貨到付款',
  linepay: 'LinePay',
  pchomepay: 'PChomePay 支付連',
  // free: '免收款',
}

export const promoReqConfig = {
  activityPrice: {
    name: '商品滿額',
    type: 'price',
    value: 'activityPrice',
    storewide: false,
  },
  activityQuantity: {
    name: '商品滿件',
    type: 'quantity',
    value: 'activityQuantity',
    storewide: false,
  },
  orderPrice: {
    name: '訂單滿額',
    type: 'price',
    value: 'orderPrice',
    storewide: true,
  },
  orderQuantity: {
    name: '訂單滿件',
    type: 'quantity',
    value: 'orderQuantity',
    storewide: true,
  },
}

export const promoContentConfig = {
  productPrice: {
    name: '每樣商品定額價格',
    type: 'price',
    value: 'productPrice',
    storewide: false,
  },
  productRebate: {
    name: '每樣商品定扣價格',
    type: 'price',
    value: 'productRebate',
    storewide: false,
  },
  productPercent: {
    name: '每樣商品打折',
    type: 'percent',
    value: 'productPercent',
    storewide: false,
  },
  orderRebate: {
    name: '訂單定扣價格',
    type: 'price',
    value: 'orderRebate',
    storewide: true,
  },
  orderPercent: {
    name: '訂單打折',
    type: 'percent',
    value: 'orderPercent',
    storewide: true,
  },
  deliveryPrice: {
    name: '運費定額價格',
    type: 'price',
    value: 'deliveryPrice',
    storewide: true,
  },
  deliveryRebate: {
    name: '運費定扣價格',
    type: 'price',
    value: 'deliveryRebate',
    storewide: true,
  },
  deliveryPercent: {
    name: '運費打折',
    type: 'percent',
    value: 'deliveryPercent',
    storewide: true,
  },
}

export const deliveryTypesConfig = {
  noShip: 'null',
  address: 'address',
}

export const deliveryTypesName = {
  [deliveryTypesConfig.noShip]: '不需運送',
  [deliveryTypesConfig.address]: '地址',
}

export const deliveryTypesList = Object.keys(deliveryTypesName).map(key => {
  return {
    value: key,
    name: deliveryTypesName[key],
  }
})

export const cvsTypes = {
  fami: 'FAMIC2C',
  unimart: 'UNIMARTC2C',
  hilife: 'HILIFEC2C',
  okmart: 'OKMARTC2C',
}

export const cvsTypesName = {
  [cvsTypes.fami]: '全家店到店',
  [cvsTypes.unimart]: '7-11店到店',
  [cvsTypes.hilife]: '萊爾富店到店',
  [cvsTypes.okmart]: 'OK店到店',
}

export const cvsTypesList = Object.keys(cvsTypesName).map(key => {
  return {
    value: key,
    name: cvsTypesName[key],
  }
})

/**
 * @name 電商模組運送方式 - 串接設定選項
 */
export const deliveryConfigOptions = {
  ecpay: 'ecpay',
}

export const deliveryConfigOptionsName = {
  [deliveryConfigOptions.ecpay]: '綠界物流',
}

/**
 * @name 電商模組運送方式 - 串接設定選項
 */
export const deliveryConfigList = Object.keys(deliveryConfigOptionsName).map(key => {
  return {
    value: key,
    name: deliveryConfigOptionsName[key],
  }
})

export const orderDeliveryStatus = {
  wait: 'wait',
  tallying: 'tallying',
  shipped: 'shipped',
  completed: 'completed',
  cancel: 'cancel',
  returned: 'returned',
}

export const orderDeliveryStatusName = {
  [orderDeliveryStatus.wait]: '等待處理',
  [orderDeliveryStatus.tallying]: '揀貨中',
  [orderDeliveryStatus.shipped]: '已出貨',
  [orderDeliveryStatus.completed]: '已送達',
  [orderDeliveryStatus.cancel]: '取消',
  [orderDeliveryStatus.returned]: '退貨',
}

export const orderDeliveryStatusList = Object.keys(orderDeliveryStatusName).map(key => {
  return {
    value: key,
    name: orderDeliveryStatusName[key],
  }
})

export const orderStatusConfig = {
  unpaid: { name: '等待付款', value: 'unpaid' },
  open: { name: '訂單成立', value: 'open' },
  picking: { name: '揀貨中', value: 'picking' },
  shipped: { name: '已出貨', value: 'shipped' },
  completed: { name: '訂單完成', value: 'completed' },
  clientCancel: { name: '客戶取消', value: 'clientCancel' },
  adminCancel: { name: '後台取消', value: 'adminCancel' },
  systemCancel: { name: '系統取消', value: 'systemCancel' },
  requestRefunded: { name: '申請退款中', value: 'requestRefunded' },
  cancel: { name: '取消', value: 'cancel' },
}

export const cancelByKeysConfig = {
  admin: { name: '後台取消', value: 'admin' },
  client: { name: '客戶取消', value: 'client' },
  system: { name: '系統取消', value: 'system' },
}

export const paymentStatusConfig = {
  unpaid: { name: '等待付款', value: 'unpaid' },
  CODpaid: { name: '貨到付款', value: 'CODpaid' },
  systemPaid: { name: '系統已付款', value: 'systemPaid' },
  adminPaid: { name: '標記已付款', value: 'adminPaid' },

  expiredCancel: { name: '逾期取消', value: 'expiredCancel' },
  cancel: { name: '取消', value: 'cancel' },
}

export const refundedStatusConfig = {
  request: { name: '申請退款中', value: 'request' },
  autoRefunded: { name: '金流已退款', value: 'autoRefunded' },
  adminRefunded: { name: '標記已退款', value: 'adminRefunded' },
}

export const orderWalletRecordStatusConfig = {
  unsed: { name: '未使用', value: 'unused' },
  paid: { name: '已支付', value: 'paid' },
  refunded: { name: '已退款', value: 'refunded' },
}

// => 發票
export const invoiceTypesConfig = {
  B2B: 'B2B',
  B2C: 'B2C',
}

export const invoiceStatusConfig = {
  uninvoiced: { name: '未開立', value: 'uninvoiced' },
  systemInvoiced: { name: '第三方已開立', value: 'systemInvoiced' },
  adminInvoiced: { name: '標記已開立', value: 'adminInvoiced' },
  allInvalid: { name: '全額作廢', value: 'allInvalid' },
  allAllowance: { name: '全額折讓', value: 'allAllowance' },
}

export const invoicePlatformConfig = {
  null: { name: '不啟用', value: 'null' },
  ezPay: { name: 'ezPay', value: 'ezpay' },
}

export const invoiceCarrierTypes = {
  einvoicePerson: '自然人憑證號碼',
  einvoicePhone: '手機條碼載具',
}

export const productRangeTypes = {
  all: { name: '全館商品', value: 'all' },
  product: { name: '單一商品', value: 'product' },
  activity: { name: '活動商品', value: 'activity' },
}

export const navSettingTypes = {
  allProduct: { name: '所有類別', value: 'allProduct' },
  link: { name: '其他連結', value: 'link' },
  ecommerceActivity: { name: '活動', value: 'ecommerceActivity' },
  ecommercePage: { name: '其他頁面', value: 'ecommercePage' },
}
